import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from "react-bootstrap";
import { showToast } from "main/shared/helpers";
import { debounce } from "lodash";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: { matchVisual: true },
};

// Optimized function to extract text length
const getHtmlTextLength = (html) => {
  if (!html) return 0;
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent.trim().length;
};

export default function ReportEditorQuill({
  text,
  onSave,
  onCancle,
  maxLength,
}) {
  const [editorHtml, setEditorHtml] = useState(text || ""); // Controls editor UI
  const [length, setLength] = useState(getHtmlTextLength(text));
  const editorHtmlRef = useRef(text || ""); // Stores latest content
  const quillRef = useRef(null);

  // Sync prop text updates
  useEffect(() => {
    if (text !== undefined && text !== editorHtmlRef.current) {
      editorHtmlRef.current = text;
      setEditorHtml(text);
      setLength(getHtmlTextLength(text));
    }
  }, [text]);

  // Debounced function to update state
  const debouncedUpdate = useCallback(
    debounce((content) => {
      editorHtmlRef.current = content;
      setLength(getHtmlTextLength(content));
    }, 300),
    []
  );

  const handleChange = (content) => {
    const newLength = getHtmlTextLength(content);

    if (newLength <= maxLength) {
      setEditorHtml(content); // Immediate UI update
      debouncedUpdate(content); // Debounced state update
    } else {
      showToast("Character limit exceeded");
    }
  };

  // Remove inline styles on paste
  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops.forEach((op) => {
          if (op.attributes) {
            op.attributes = {
              header: op.attributes.header,
              bold: op.attributes.bold,
              italic: op.attributes.italic,
              underline: op.attributes.underline,
              list: op.attributes.list,
              indent: op.attributes.indent,
              link: op.attributes.link,
            };
          }
        });
        return delta;
      });
    }
  }, []);

  return (
    <>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={editorHtml}
        onChange={handleChange}
        modules={modules}
      />
      <div className="rightsideactions">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <small>
            {length}/{maxLength}
          </small>
          <div className="d-flex mt-3">
            <Button
              className="text-secondary500 bg-transparent border-0"
              onClick={onCancle}
            >
              Cancel
            </Button>
            <button
              className="btn btn-primary"
              onClick={() => onSave && onSave(editorHtmlRef.current)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
